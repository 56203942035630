import React from 'react'

import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import Hero from "../../components/hero-our-model"
import Content from "../../components/books-content"

const BooksPage = () => {
    
    const {t} = useI18next();
    
    return (
        <Layout>
            <SEO title={t("PUBLICATIONS.BOOKS")} />
            <Content />
        </Layout>
    )
}

export default BooksPage
import React from 'react';
import { graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"
import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';

import { Link as ScrollToLink } from 'react-scroll'
import styled from "styled-components"
import SectionHeader from './section-header';
import Button from '@material-ui/core/Button';
import backgroundImage from '../images/background-books.jpg';
import slugify from 'slugify';

const Container = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: -131px;
    padding-top: 131px;

    background-image: url(${(props)=>props.backgroundImage});
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
`

const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
`

const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;
    // float: left;
    
    margin-left: 0;
    margin-right: 0;
    
    @media screen and (max-width: 810px) {
        width: 100%;
    }
   

`

const ArticlesContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Article = styled.div`
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    background: white;
    padding: 32px;
`

const ArticleInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    h1 {
        text-align: left;
        
        // &:hover {
        //     color: #ff8000;
        // }
    }
    
    h2 {
        text-align: left;
        font-weight: 100;
    }
    
    ul {
        text-align: left;
        font-size: 18px;
        list-style-position: inside;
    }
`


const BookCoverWrapper = styled.div`
    width: 30%;
    overflow: hidden;
    
    
    &.to-the-left {
        float: left;
        margin-right: 16px;
    }
    
    &.to-the-right {
        float: right;
        margin-left: 16px;
    }
    
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }
`

const BookTitleWrapper = styled.div`
    margin-bottom: 16px;
`


const SideBar = styled.div`
    float: right;
    width: 25%;
    
    @media screen and (max-width: 810px) {
        width: 100%;
    }
    
`


const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    max-width: 90%;
    max-width: 1372px;
    display: flex;
    justify-content: space-between;
    
    @media screen and (max-width: 810px) {
        flex-direction: column-reverse;
    }

    
    p { 
        
        font-size: 18px;
        line-height: 1.4;
        // margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
    }
    
  
`

const BookCollection = styled.div`
    position: sticky;
    top: 106px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    
    a {
        width: 24%;
        width: 85px;
        // height: 120px;
        margin-left: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        
        .gatsby-image-wrapper {
            height: 125px;
        }
        
        img {
            width: auto;
            height: 100%;
            object-fit: cover;
        }
    }

`

const ReadMoreUrl = styled.div`
    width: auto;
    text-align: left;
    font-size: 18px;
`

const BooksContent = () => {
    
    const context = React.useContext(I18nextContext);
    
    const {t} = useI18next();
    const language = context.language;
    
    const items = useStaticQuery(graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(books)/"  }} sort: {fields: frontmatter___weight, order: DESC}) {
          edges {
            node {
              frontmatter {
                title
                language
                author
                link
                weight
                cover {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                }
              }
              html
              id
            }
          }
        }
      }
    `)
    let books = items.allMarkdownRemark.edges
    let booksFilteredByLanguage = books.filter(book=>book.node.frontmatter.language == language)
    
    return (
        <Container backgroundImage={backgroundImage}>
            <ContentWrapper id="mission" >
                <SectionHeader id="section-header" title={t("PUBLICATIONS.BOOKS")}/>
                <Content>
                    <ContentInner>
                        <ArticlesContainer>
                            {
                                booksFilteredByLanguage.map((book, index)=>{
                                    let contentId = slugify(book.node.frontmatter.title, {remove: /[…...?!']/g, lower: true});

                                    return (
                                        <Article id={contentId} key={book.node.id}>
                                            <ArticleInner>
                                                <BookTitleWrapper>
                                                    <h1>{book.node.frontmatter.title}</h1>
                                                    <h2>{book.node.frontmatter.author && book.node.frontmatter.author.map((author, index)=>{
                                                        return(
                                                            <span key={index}>
                                                                {author}
                                                                {(index < book.node.frontmatter.author.length - 1) ? ", " : ""}
                                                            </span>
                                                        )})}
                                                    </h2>
                                                </BookTitleWrapper>
                                                <div>
                                                    <BookCoverWrapper className={index % 2 === 0 ? "to-the-left" : "to-the-right"}>
                                                        <Img 
                                                            fluid={book.node.frontmatter.cover.childImageSharp.fluid && book.node.frontmatter.cover.childImageSharp.fluid}
                                                            alt={book.node.frontmatter.title}
                                                        />
                                                        {/*<img src={book.node.frontmatter.cover.childImageSharp.fluid} alt={book.node.frontmatter.title} />*/}
                                                    </BookCoverWrapper>
                                                    
                                                    <div dangerouslySetInnerHTML={{__html: book.node.html}} />
                                                    {
                                                        book.node.frontmatter.link && <ReadMoreUrl><a href={book.node.frontmatter.link} target="_blank"> {t("PUBLICATIONS.READ-MORE")} </a></ReadMoreUrl> 
                                                    }
                                                </div>
                                            </ArticleInner>
                                        </Article>
                                    )
                                    
                                })
                            }
                        </ArticlesContainer>
                        
                    </ContentInner>
                    <SideBar>
                            <BookCollection>
                                {
                                    booksFilteredByLanguage.map((book)=>{
                                        let contentId = slugify(book.node.frontmatter.title, {remove: /[…...?!']/g, lower: true});
                                        contentId = contentId.toLowerCase();
                                        return (
                                            <ScrollToLink key={book.node.id} to={contentId} spy={true} smooth={true} offset={-105} duration={1000}>
                                             
                                                    <Img 
                                                        fluid={book.node.frontmatter.cover.childImageSharp.fluid}
                                                        alt={book.node.frontmatter.title}
                                                        title={book.node.frontmatter.title}
                                                    />
                                             
                                            </ScrollToLink>
                                        )
                                    })
                                }
                            </BookCollection>
                        </SideBar>
                </Content>
            </ContentWrapper>
        </Container>
    
    )
}

export default BooksContent